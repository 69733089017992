import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {UserService} from "./user.service";
import {Observable} from "rxjs";
import {ILoginResponse, IResponse} from "./interfaces/response";

@Injectable({
  providedIn: 'root'
})
export class BenchmarkService {

    constructor(private http: HttpClient) {}

    _benchmarkTypesUrl: string = UserService.getHostname() + "/benchmarks/types";
    _recentBenchmarksUrl: string = UserService.getHostname() + "/builds/recent";
    _editBuildUrl: string = UserService.getHostname() + "/builds/edit";
    _addBenchmarkFileUrl: string = UserService.getHostname() + "/benchmarks/upload_file"; // TODO
    _addBenchmarkDataUrl: string = UserService.getHostname() + "/benchmarks/add";
    _createComparisonUrl: string = UserService.getHostname() + "/comparisons/create";
    _deleteComparisonUrl: string = UserService.getHostname() + "/comparisons/delete";
    _deleteBenchmarkUrl: string = UserService.getHostname() + "/benchmarks/delete";
    _deleteBuildUrl: string = UserService.getHostname() + "/"; // TODO
    _benchmarksUrl: string = UserService.getHostname() + "/benchmarks/results";  // TODO: comparison creation page
    _resultUrl: string = UserService.getHostname() + "/builds/";
    _deletePhotoUrl: string = UserService.getHostname() + "/builds/delete_image";  // TODO
    headers = new HttpHeaders({
        "Content-Type": "application/json",
    })
    getRecentBenchmarks(): Observable<IResponse> {
        return this.http.get<IResponse>(this._recentBenchmarksUrl, {})
    }
    getBenchmarkTypes(): Observable<ILoginResponse> {
        return this.http.get<ILoginResponse>(this._benchmarkTypesUrl, {})
    }
    editTitle(buildId, titleValue): Observable<any> {
        return this.http.post<any>(this._editBuildUrl, {"title": titleValue, "build_id": buildId}, {})
    }
    uploadPhoto(formData, buildId): Observable<IResponse> {
        return this.http.post<IResponse>(UserService.getHostname() + `/builds/${buildId}/upload_image`, formData, {})
    }
    createComparison(benchmarks, title, desc): Observable<IResponse> {
        return this.http.post<IResponse>(this._createComparisonUrl, {benchmarks: benchmarks, title: title, description: desc}, {})
    }
    createInstantComparison(benchmarks): Observable<IResponse> {
        return this.http.post<IResponse>(this._createComparisonUrl, {benchmarks: benchmarks, title: "Instant Comparison", description: ''}, {})
    }

    addBenchmarkFile(benchmark): Observable<IResponse> {
        return this.http.post<IResponse>(this._addBenchmarkFileUrl, benchmark, {headers: this.headers})
    }
    addBenchmarkData(benchmark): Observable<IResponse> {
        return this.http.post<IResponse>(this._addBenchmarkDataUrl, benchmark, {})
    }
    getBenchmarks(): Observable<IResponse> {
        return this.http.post<IResponse>(this._benchmarksUrl, {}, {})
    }
    deleteComparison(compareId): Observable<IResponse> {
        return this.http.delete<IResponse>(`${this._deleteComparisonUrl}/${compareId}`, {})
    }
    deleteBenchmark(buildId, benchmarkId): Observable<IResponse> {
        return this.http.delete<IResponse>(
            `${this._deleteBenchmarkUrl}`, {
                body:{
                    build_id: buildId,
                    benchmark_id: benchmarkId
                }
            }
        )
    }
    deleteProfile(resultId): Observable<IResponse> {
        return this.http.post<IResponse>(this._deleteBuildUrl, {resultId: resultId}, {})
    }
    getProfile(resultId): Observable<IResponse> {
        return this.http.get<IResponse>(this._resultUrl + resultId, {})
    }
    deletePhoto(resultId, image): Observable<IResponse> {
        return this.http.delete<IResponse>(this._deletePhotoUrl, {body: {build_id: resultId, image: image}})
    }

}
